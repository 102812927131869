import { Injectable } from '@angular/core'
import { Server } from './server'
import { FleetVehicleInfo } from '@boa/booster-types'
import { FindOptions } from '@boa/app/_shared/types/find-options.model'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class FleetVehicleInfoService {
  constructor(private server: Server) {}

  find(options: FindOptions) {
    return this.server.get(`/v2/boa/FleetVehicleInfo`, options)
  }

  findById(id: string) {
    return this.server.get(`/v2/boa/FleetVehicleInfo/${id}`)
  }

  create(
    options: FleetVehicleInfo
  ): Observable<{ fleetVehicleInfo: FleetVehicleInfo }> {
    return this.server.post(`/v2/boa/FleetVehicleInfo`, options)
  }

  deleteById(id: string) {
    return this.server.del(`/v2/boa/FleetVehicleInfo/${id}`)
  }

  update(
    id: string,
    params: FleetVehicleInfo | FormData
  ): Observable<{ fleetVehicleInfo: FleetVehicleInfo }> {
    return this.server.post(`/v2/boa/FleetVehicleInfo/${id}`, params)
  }
}
